.appbar {
  height: 100vh;
  float: none;
  position: fixed;
  z-index: 1;
}


.appbar .add-place{
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  background-color: #7BA87B;
  width: fit-content;
  height: 40px;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  margin-top: -40px;

  border-radius: 15px;

  cursor: pointer;

  transition: border-radius 0.5s, background-color 0.5s;
}

.appbar .add-place:hover{

  background-color: #664B7F;

  border-radius: 5px;
}


.appbar .add-place p {
  color: white;
  font-size: 14px;
  font-weight: 500;
padding-top: 10px;
}

.appbar .add-place svg {
  color: white;
  margin-top: 7px;
  margin-right: 4px;
}




.appbar .text-input {
  width: fit-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}



.appbar .text-input p {
  margin-bottom: 0;
  color: #919191;
  font-size: 14px;
}

.appbar .text-input input {
  padding: 0px 10px;
  background-color: #F2F2F2;
  border-style: solid;
  border: #F2F2F2;
  height: 40px;
  width: 250px;
  border-radius: 10px;
}



/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: white;
  margin: auto;
  padding: 20px;
border-radius: 10px;
  width: 400px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}




.create-button {
  background-color: #AFCAAF;
  height: 35px;
  border-radius: 12px;
  margin: 0 10px;
  cursor: pointer;

  transition:  border-radius 0.5s;
}

.create-button:hover {
  /*background-color: #7BA87B;*/
  border-radius: 5px;
}

.create-button p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 15px;
  margin-top: 5px;
}



.cancel-button {
  background-color: #664B7F;
  height: 35px;
  border-radius: 12px;
  margin: 0 10px;
  cursor: pointer;

  transition:  border-radius 0.5s;
}

.cancel-button:hover {
  /*background-color: #664B7F;*/
  border-radius: 5px;
}

.cancel-button p {
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 0 15px;
  margin-top: 5px;
}




.appbar label {
  padding: 2px 5px;
  background-color: #7BA87B;
  color: white;
  font-size: 11px;
  border-radius: 5px;
  margin-left: 10px;
}




.appbar .place {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-bottom: 10px;
  padding: 0 10px;
  cursor: pointer;

  text-align: center;
  justify-content: center;
  align-items: center; 
}


.appbar .place p {
  color: white;
  font-size: 20px;
  margin: 0;
}

.appbar .place p:hover {
  color: #664B7F;
  font-weight: 600;
}


.appbar .sellected-place {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-bottom: 10px;
  padding: 0 10px;
  cursor: pointer;
  
  text-align: center;
  justify-content: center;
  align-items: center; 
}

.appbar .sellected-place p {
  color: #664B7F;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}







.appbar .dropdown-button {
  display: flex;
  align-items: center;
}


.appbar .dropdown-button svg {
  color: white;
  font-size: 50px;
}












.appbar .line {
  width: 80%;
  height: 1px;
  background-color: white;

  margin: 20px auto;
}



.appbar .menu-button {
  font-size: 40px;
  float: left;
  position: fixed;
  left: 20px;
  top: 20px;
  color: #A8A8A7;
}



.appbar .menu-button:hover {
  cursor: pointer;
  color: #664B7F;
}

.sidenav {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */

  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background-color: #AFCAAF;
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
  text-align: left;
}



/* The navigation menu links */
.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #FFFFFF;
  display: block;
  transition: 0.3s;
  font-weight: 500;
}

.sidenav .sellected {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 20px;
  color: #664B7F;
  display: block;
  transition: 0.3s;
  font-weight: 500;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #664B7F;
  cursor: pointer;
}

/* Position and style the close button (top right corner) */
.sidenav .closebtn {
  position: fixed;
  top: 10px;
  left: 160px;
  font-size: 36px;


  text-decoration: none;
  font-size: 35px;
  color: #FFFFFF;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn:hover {
  color: #755D8B;
  cursor: pointer;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s;
  padding: 20px;
}




.sidenav .logout {
  font-size: 20px;
  position: fixed;
  left: 30px;
  bottom: 20px;
  display: flex;
  justify-content: space-around;
  width: 70px;
  color: white;
}



.sidenav .logout:hover {
  color: #664B7F;
  cursor: pointer;
}









@media screen and (max-width: 1000px) {



  .appbar .add-place{

    margin-top: 30px;

  }

  
}



/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }

  .sidenav a {
    font-size: 18px;
  }

  
}