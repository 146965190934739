.menu {
    min-height: 50vh;
    text-align: left;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;

    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
}


.menu .left {
    margin: 20px;
}

.menu .right {
    margin: 20px;
}


.menu .text-input {
    margin-bottom: 10px;
}

.menu .text-input p {
    margin-bottom: 0;
    color: #919191;
    font-size: 14px;
}

.menu .text-input input {
    padding: 0px 10px;
    background-color: #F2F2F2;
    border-style: solid;
    border: #F2F2F2;
    height: 40px;
    width: 250px;
    border-radius: 10px;
    display: inline-block;
}


.menu .text-input .price {
    width: 75px;
    margin-left: 5px;
}


.menu .add-button {
    margin-left: 5px;

    background-color: #95B995;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    transform: rotate(0deg);
    transition: transform 0.5s;

    float: left;
}


.menu .add-button:hover {
    cursor: pointer;
    transform: rotate(90deg);

}


.menu .edit-button {
    margin-left: 5px;

    background-color: #95B995;
    height: 40px;
    width: 40px;
    border-radius: 10px;

    transition: width 0.5s, border-radius 0.5s;

    float: left;
}


.menu .edit-button:hover {
    cursor: pointer;
    border-radius: 5px;
    width: 55px;

}




.menu .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7.5px;
    color: #ffffff;

}

.menu .icon:hover {
    cursor: pointer;
}





.icon-category {
    color: #C64D54;
    transition: color 0.5s;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.icon-category:hover {
    cursor: pointer;
    color: #664B7F;
}






.menu .tab {
    background-color: #D7E4D7;
    margin-bottom: 10px;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}


.menu .tab:hover {
    background-color: #D1C9D8;
    cursor: pointer;
}

.menu .tab p {
    font-size: 16px;
    font-weight: 400;
    padding: 7px 10px;
    margin: 0;
}





 .uploaded-files {
   
   margin: 10px;

}

 .file-box {
    margin: auto;
    display: block;

    width: max-content;
    padding: 10px 10px;
}

.file-box a {
    color: black;
    text-decoration: none;
    margin-right: 10px;
    font-size: 12px;
}

.file-box a:hover {
    color: #664B7F;
    text-decoration:underline;
}




@media only screen and (max-width: 850px) {

    .menu {

        margin-left: 5px;
        margin-top: 50px;
    }


}



@media only screen and (max-width: 480px) {




    .menu .text-input input {
        width: 160px;
        font-size: 11px;
    }


    .menu .text-input .name {
        width: 70%;
    }

    .menu .text-input .price {
        width: 50px;
    }

}