
.banner {
    border-radius: 20px 20px 0 0;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: fit-content;
    height: fit-content;


    margin-left: auto;
    margin-right: auto;
    display: block;

    animation: bannermove 1s;
}


@keyframes bannermove {
    from {bottom: -60px;}
    to {bottom: 0px;}
  }



.banner h6 {
    margin: 20px;
    color: white;
}
