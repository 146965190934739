.images {
  display: block;
  margin-left: 60px;
  margin-top: 50px;
  margin-right: auto;
}


.images .row {
  display: block;
  margin-bottom: 100px;
  height: fit-content;
  
}


.images .header {
  display: flex;
  margin-bottom: 10px;

}


.images .header h4 {
  margin-left: 10px;
  margin-top: 4px;
  font-size: 20px;
}


 .file-input::-webkit-file-upload-button {
  visibility: hidden;
}

 .file-input {
  margin-right: auto;
  display: inline-block;
  width: 94px;
  margin-left: 10px;
  transition: width 0.5s;
}

 .file-input:hover {
  width: 114px;

}



 .file-input::before {
  content: "Pievienot";
  display: inline-block;
  background: #664B7F;
  color: white;
  padding: 5px 10px;
  outline: none;
  white-space: nowrap;

  cursor: pointer;

  border-radius: 10px;
  font-weight: 500;
  font-size: 12pt;

  transition: padding 0.5s;
}

 .file-input:hover::before {
  padding: 5px 20px;
}

 .file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}



.images .uploaded-images {
  display: flex;
  flex-wrap: wrap;
}


.images .uploaded-images .image-box {
  position: relative;
  display: inline-block;
margin-left: 0;
}


.images .uploaded-images .trash {
  position: absolute;
  top: 15px;
  right: 15px;
  float: left;
  color: white;
  font-size: 20px;
  transition: color 0.5s, font-size 0.5s;
}

.images .uploaded-images .trash:hover {
  position: absolute;
  top: 15px;
  right: 15px;
  float: left;
  color: #C64D54;
  font-size: 30px;
  cursor: pointer;
}


.images .uploaded-images .upload {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border: solid #262624 2px;
  border-radius: 20px;
  margin: 10px;

}




.images .preview-container {
  width: 200px;
  height: 200px;
  overflow: hidden;
  margin: 10px;
  border: solid #262624 2px;
  border-radius: 20px;
  display: inline-block;
  float: left;
}





.images .preview-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}





@media only screen and (max-width: 525px) {


  .images {
    margin-left: 0px;
  }

  .images .header {
    display: flex;
    flex-direction: column;
  }

  .images .file-input::before {
    margin-bottom: 10px;
  }


  .images .uploaded-images {
    display: block;
  }

}