.about {
    text-align: left;
}


.about .accesscode {
    padding: 5px;
    background-color: #F2F2F2;
    width: fit-content;
    margin: 20px auto;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
}


.about .accesscode p {
    padding: 5px;
    margin: 0;
}


.about .flex-contaner {
    margin-top: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.about .flex {
    display: flex;
    flex-direction: column;
}





.about .toggle {

    width: fit-content;
}

.about .toggle p {
    margin-bottom: 0;
    color: #919191;
    font-size: 14px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: block;
    width: 60px;
    height: 34px;
    margin-bottom: 25px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #A393B2;
    -webkit-transition: .5s;
    transition: .5s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .5s;
    transition: .5s;
}

input:checked+.slider {
    background-color: #664B7F;
}

input:focus+.slider {
    box-shadow: 0 0 1px #A393B2;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}


.about .row {
    margin-top: 3rem;
}

.about .text-input {
    width: fit-content;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
}



.about .right {
    margin-left: 0;
}

.about .text-input p {
    margin-bottom: 0;
    color: #919191;
    font-size: 14px;
}

.about .text-input input {
    padding: 0px 10px;
    background-color: #F2F2F2;
    border-style: solid;
    border: #F2F2F2;
    height: 40px;
    width: 250px;
    border-radius: 10px;
}


.about .text-input textarea {
    padding: 5px 10px;
    background-color: #F2F2F2;
    border-style: solid;
    border: #F2F2F2;
    height: 200px;
    width: 80%;
    border-radius: 10px;
}


.about .working-hours {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
}

.about .time-row {
    display: flex;
    width: 250px;
    align-items: baseline;
    margin-bottom: 0.7rem;
}

.about .day-row {
    display: flex;
    align-content: space-between;
    flex-direction: column;
    justify-content: space-around;
}

.about .day-row p {
    margin-bottom: 12px;
}

.about .time-row p {
    margin-right: 1rem;
}

.about .time-row h5 {
    color: #919191;
}


.about .time-row select {
    border-radius: 10px;
    height: 40px;
}

.about .time-row select {
    appearance: none;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('');
    background-repeat: no-repeat;
    background-position: right 1em top 50%;
    padding-right: 5px;
    padding-left: 5px;
    background-color: #F2F2F2;
    border: none;
    margin: 0px 10px;

}










.autocomplete {
    /*the container must be positioned relative:*/
    position: relative;
    display: inline;
    width: 250px;
    height: 40px;
}

.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: #e9e9e9;
}

.autocomplete-active {
    /*when navigating through the items using the arrow keys:*/
    background-color: DodgerBlue !important;
    color: #ffffff;
}




.about .add-button {
    margin: 0px 12px;

    background-color: #95B995;
    height: 40px;
    width: 40px;
    border-radius: 10px;
    transform: rotate(0deg);
    transition: transform 0.5s;

}


.about .add-button:hover {
    cursor: pointer;
    transform: rotate(90deg);

}

.about .add-button .icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 7.5px;
    color: #ffffff;

}

.about .add-button .icon:hover {
    cursor: pointer;
}





.about .text-input .pill-row {
    flex-direction: row;
    display: flex;
}

.about .pill-bundle {
    margin-top: 5px;

}

.about .pill {
    display: flex;
    margin-bottom: 10px;
    height: 30px;
    background-color: #F2F2F2;
    border-radius: 8px;
    width: max-content;
}

.about .pill p {
    color: black;
    font-weight: 600;
    font-size: 14px;
    padding: 0px 8px;
    margin-top: 5px;
}

.about .pill .icon {
    margin-top: 3px;
    margin-right: 5px;
    color: #919191;
    transform: rotate(45deg);
    transition: transform 0.5s;
}

.about .pill .icon:hover {
    cursor: pointer;
    color: #664B7F;
    transform: rotate(135deg);
}



.about .pill-sellector {
    height: 150px;
    overflow-y: auto;
    width: 170px;
    padding: 10px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
}


.about .pill-sellector .pill:hover {
    cursor: pointer;
    background-color: #AFCAAF;
}






.about .places-container {
    position: relative;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
}

.about .map-container {
    width: 90%;
    height: 400px;
    margin-bottom: 3rem;
    border-radius: 20px;
}


.about .text-input .maps-autocomplite {
    width: 500px;
}



@keyframes button {
    from {
        top: -50px;
    }

    to {
        top: 5px;
    }
}



.about .save-button {
    z-index: 1;
    height: 50px;
    width: 270px;
    line-height: 55px;
    padding: 0 0.7rem;
    background-color: #664B7F;

    color: white;
    position: fixed;
    top: 5px;
    left: 20px;
    border-radius: 15px;
    transition: border-radius 0.5s, width 0.5s;
    animation-duration: 4s;
    animation-name: button;
}

.about .save-button:hover {
    cursor: pointer;
    border-radius: 5px;
    width: 230px;
}


.about .save-button h5 {
    display: inline-block;
    vertical-align: middle;
}



.about .delete-place {
    color: #C64D54;
    transition: color 0.5s;
    display: block;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 20px;
    font-size: 30px;
}

.about .delete-place:hover {
    cursor: pointer;
    color: #664B7F;
}




@media only screen and (max-width: 1350px) {

    .about {
        margin-left: 20px;
    }

    .about .flex-contaner {
        margin-top: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: max-content;
        flex-direction: column;
        justify-content: center;
    }


    .about .text-input textarea {
        height: 200px;
        width: 60vw;
    }


    .about .map-container {
        width: 60vw;
    }

}



@media only screen and (max-width: 1000px) {


    .about .text-input textarea {
        height: 200px;
        width: 85vw;
    }

    .about .map-container {
        width: 85vw;
    }



    .about .pill-row {
        display: flex;
        flex-direction: column;
    }

}




@media only screen and (max-width: 770px) {

    .about .text-input .pill-row {
        flex-direction: column;
        display: flex;
    }


}



@media only screen and (max-width: 590px) {


    .about .text-input .maps-autocomplite {
        width: 85vw;
    }


}