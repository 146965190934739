.registration {
    position: relative;
    vertical-align: middle;
    text-align: center;
}


.registration .row {
    height: 100vh;
    display: flex;
    align-items: center;
}


.registration form {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
margin-top: 20px;
    margin-bottom: 3rem;
}

.registration input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    margin-top: 12px;
    padding: 10px;
    font-size: 12px;
    background-color: #F2F2F2;
    border: #F2F2F2;
    border-radius: 10px;
}






.registration .left-side {
    padding-top: 0rem;
}

.registration .left-side h3 {
    font-weight: 300;
    margin-bottom: -1rem;
}


.registration .left-side p {

    margin-top: 2.5rem;
}

.registration .left-side .about {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}




.registration .button {
    background-color: #664B7F;
    border: #664B7F;
    border-radius: 20px;
    margin-top: 1.5rem;
    padding: 10px 30px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: white;
    font-weight: 400;
    font-size: 18px;

    transition: border-radius 0.5s;
}

.registration .button:hover {
    border-radius: 10px;
    cursor: pointer;
}


.registration .left-side button {
    background-color: #664B7F;
    border: #664B7F;
    border-radius: 20px;
    margin-top: 1.5rem;
    padding: 8px 30px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    color: white;
    font-weight: 400;
    font-size: 18px;

    transition: border-radius 0.5s;
}


.registration .left-side button:hover {
    border-radius: 10px;
}


.registration .left-side a {
    text-decoration: none;
    color: #664B7F;
}




.registration .forgot {
    color: #664B7F;
    font-weight: 400;
    width: fit-content;


}

.registration .forgot:hover {
    cursor: pointer;
    font-weight: 600;
}






.registration .social-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 240px;
    margin: 10px auto;
    border-radius: 20px;
    background-color: #DB4437;
    cursor: pointer;

    transition:  border-radius 0.5s;
}

.registration .social-button:hover {
    border-radius: 10px;
}

.registration .social-button p {
    margin: 0 10px;
    margin-left: 5px;
    color: white;
}

.registration .social-button svg {
    margin: 10px;
    margin-right: 0px;
    color: white;
}



.registration .info p {
    margin-bottom: 2px;
    margin-top: 2px;
    font-size: 9px;
}











.registration .right-side {
    padding-top: 3rem;
    background-color: #F2F2F2;
    height: 100%;
    display: inline-block;
}


.registration .right-side .logo {
    width: 260px;
    margin-bottom: 3rem;
}


.registration .right-side .about {
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: left;
    text-indent: 20px;
}


.registration .right-side .about h5 {
    font-weight: 500;
    font-size: 18px;
}

.registration .right-side .about p {
    font-weight: 500;

}



.registration .right-side .humans {
    width: 60%;

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}



@media only screen and (max-width: 850px) {

    .registration .right-side .about {
        width: 95vw;
    }

    .registration .left-side .about {
        width: 95vw;
    }


}